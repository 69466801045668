<template>
<div id="DailyLearn">  
  <Header/>
  <CommonAlert1 ref="commonAlert1" :title="title" :content="content" :btnnameup="btnNameUp" :onfunc="onFuncUp"/>
  <CommonAlert2Func ref="commonAlert2Func" :title="title"  :content="content" :btnnameup="btnNameUp" :btnnamedown="btnNameDown" :onfuncup="onFuncUp" :onfuncdown="onFuncDown"/>
  <DailyLearnVAlert ref="dailyLearnVAlert" :onfuncup="onFuncUp" :onfuncdown="onFuncDown"/>
  <DailyLearnTrophyAlert ref="dailyLearnTyophyAlert" :onfuncup="onFuncUp" :onfuncdown="onFuncDown"/>

  <body class=" bg-white">
    <nav class="navbar bg-white fixed-top nicole-noshadow">
      <div class="container">
        <a class="navbar-brand font-weight-bolder me-0 col-4" rel="tooltip" data-placement="bottom" @click="onBack">
          <!-- <img class="avatar avatar-sm" src="assets/img/icon-back-black.png"> -->
        </a>            
        <a class="navbar-brand font-weight-bold me-0 col-4 text-center" rel="tooltip" data-placement="bottom">
          每日學習
        </a>             
        <a class="navbar-brand font-weight-bolder me-0 text-right col-4" >
        </a> 
      </div>
    </nav>    
    <div class="nicolespace"></div>

    <!-- 個人資料 -->
    <div class="container mt-1">
      <div class="text-center"> 
        <span class="badge rounded-pill badge-success bg-success-soft text-dark-success text-smm text-simbold py-1 border-0">  
          <img v-if="config && config.old_images && config.old_images=='Y'" v-real-img="'data:image/jpeg;base64,'+icon" src="assets/img/camera-no.png" class="avatar avatar-sm me-2 " style="object-fit: cover;">
		  <img v-if="config && config.old_images && config.old_images=='N'" v-real-img="''+icon" src="assets/img/camera-no.png" class="avatar avatar-sm me-2 " style="object-fit: cover;">
		  哈囉，{{name}}
        </span>
      </div>
    </div>
    <!-- 月曆 -->
    <div class="container mt-3">
      <div class="">
        <div class=" mx-auto">
          <div class="accordion" id="accordionRental" >
            <div class="accordion-item mb-3 card shadow-s">
                <h5 class="accordion-header" id="headingOne" > 
                  <!-- <img src="assets/img/img035.png" class="avatar avatar-xs  my-3 me-3 ms-4 position-absolute end-0 z-index-3 "  data-bs-toggle="modal" data-bs-target="#notice9"> -->
                  <img src="assets/img/img035.png" class="avatar avatar-xs  my-3 me-3 ms-4 position-absolute end-0 z-index-3" @click="showModal('#notice9')">
                  <button class="accordion-button collapsed z-index-1 " type="button" aria-expanded="false" aria-controls="collapseOne">
                    <div class="d-flex" >
                      <span class=" text-success text-s font-weight-bold">學習日曆{{currYear}}年{{currMonth}}月</span>
                      <i class="fas fa-chevron-circle-up text-n  ms-2 text-light mt-1" v-show="showMonth" @click="openCalendar"></i>
                      <i class="fas fa-chevron-circle-down text-n ms-2 text-light mt-1" v-show="showWeek" @click="openCalendar"></i> 
                    </div>
                    <div class="accordion-body text-sm text-center col-md-10 col-xl-8  mx-auto" >
                      <table class="table table-borderless  table-xxs mb-0 text-dark textday">
                          <thead>
                            <tr class="text-gray">
                              <td>日</td>
                              <td>一</td>
                              <td>二</td>
                              <td>三</td>
                              <td>四</td>
                              <td>五</td>
                              <td>六</td>
                            </tr>
                          </thead>
                          <tbody id="days">

                          </tbody>
                      </table>
                    </div>
                  </button>
                </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 學習時間累計 -->
    <div class="container mb-3">
      <div class="bg-success-soft rounded-3">
        <div class="d-flex border-bottom-white ">
          <div class="w-50 text-center border-right-white py-2">
            <h6 class="text-dark text-sm text-simbold ">今日學習</h6>
            <div class="text-success text-s text-bold "> 
              {{todayLearn}} <span class="titlebox text-normal text-dark mx-1">分鐘</span>
            </div>
          </div>
          <div class="w-50 text-center py-2">
            <h6 class="text-dark text-sm text-simbold ">累積學習</h6>
            <div class="text-success text-s text-bold ">
              {{countHour}}
              <span class="titlebox text-normal text-dark mx-1">小時</span>
              {{countMinutes}}
              <span class="titlebox text-normal text-dark">分鐘</span> 
            </div>
          </div>
          <div class="d-flex">
            <div class="w-50"></div>
            <div class="w-50"></div>
          </div>
        </div>
        <div class="d-flex  border-bottom-white">
          <div class="w-50 text-center border-right-white py-2">
            <h6 class="text-dark text-sm text-simbold ">學習狀態</h6>
            <h6 class="mb-0 text-success text-s text-bold ">{{englishName}}</h6>
          </div>
          <div class="w-50 text-center py-2">
            <h6 class="text-dark text-sm text-simbold ">已學習</h6>
            <div class="text-success text-s text-bold ">
              {{currentLesson}}
              <span class="titlebox text-normal text-dark mx-1">/</span>
              <span class="titlebox text-normal text-dark">{{maxLesson}}</span> 
            </div>
          </div>
          <div class="d-flex">
            <div class="w-50"></div>
            <div class="w-50"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 恭喜文案1 -->
    <div class="container mb-3" v-if="isShowEnglishColor == showBlue">
      <div class=" w-100 bg-info p-2 px-3 rounded-3"> 
        <h5 class="mb-0 text-sm text-info2  lh-base"> <i class="fas fa-pencil-alt text-info2 text-smm me-2" ></i>{{englishText}}</h5>
      </div>    
    </div> 
    <!-- 恭喜文案2 -->
    <div class="container mb-3" v-else-if="isShowEnglishColor == showRed">
      <div class=" w-100 bg-soft-red p-2 px-3 rounded-3"> 
        <h5 class="mb-0 text-sm text-pink  lh-base"> <i class="fas fa-pencil-alt text-pink text-smm me-2" ></i>{{englishText}}</h5>
      </div>    
    </div>
    <!-- 恭喜文案3 -->
    <div class="container mb-3" v-else-if="isShowEnglishColor == showYellow">
      <div class=" w-100 bg-soft-yellow p-2 px-3 rounded-3"> 
        <h5 class="mb-0 text-sm text-darkbrown  lh-base"> <i class="fas fa-graduation-cap text-darkbrown text-smm me-2" ></i>{{englishText}}</h5>
      </div>    
    </div>

    <!-- 解鎖新課程按鈕 -->
    <div class="container mb-3">
      <div class="d-flex align-content-center justify-content-between">
        <div class="d-flex align-content-center">
          <div class="text-dark text-bold text-mm">本週學習任務 </div> 
          <div class="ms-3 ms-md-4" style="position: relative;">
            <!-- 灰色 -->
            <img src="assets/img/unlock-lesson.png" class="lockpic" v-if="isAddedNew" > 
            <!-- 綠色 -->
            <img src="assets/img/lock-lesson.png" class="lockpic" @click="unlockNewLesson"  v-else>
            <div class="tooltip fade show bs-tooltip-bottom whitepop z-index-0" role="tooltip" data-popper-placement="bottom" v-show="!isAddedNew">
              <div class="tooltip-arrow white-arrow"></div>
              <div class="tooltip-inner text-nowrap py-2 text-sm rounded-3" style="background-color: #8ff7ee;" >自訂課程後，記得要點選解鎖新課程喲！</div>
            </div>
          </div>
        </div>
        <!-- <img src="assets/img/img035.png" class="avatar avatar-xs mt-1" data-bs-toggle="modal" data-bs-target="#notice7"> -->
        <img src="assets/img/img035.png" class="avatar avatar-xs mt-1" @click="showModal('#notice7')">
      </div> 
    </div> 

    <!-- 無資料 -->
    <section class="my-5" v-if="isNoData">
      <div class="container">
        <div class="card-body pt-5 text-center">
          <img class="nicolepic" src="assets/img/ic_notice_black.png">
          <h5 class="my-4 text-light text-smm text-simbold">
            尚未安排學習任務... <br>趕快確認等級讓我們幫你安排課程
          </h5>
         </div>  
      </div>
    </section>

    <!-- 本周學習任務 Body -->
    <div class="container" v-else>
      <div v-for="(item, index) in tempLessonList" :key='item.lsid'>
        <!--一個組合-->
        <div class="mb-4" @click="openLesson(item)">  
          <div class="d-flex ">
            <div class="w-30">
              <div class="text-success text-mm text-bold mb-2 ms-1" v-if="item.lesson.ltid == 'essay'">Mission {{index+1}} </div>  
              <div class="text-success text-mm text-bold mb-2 ms-1" v-else>Extra </div>
              <img v-if="config && config.old_images && config.old_images=='Y' && 
                  item.lesson.ltid == 'essay'" v-real-img="'data:image/jpeg;base64,'+item.lesson.image_url" src="assets/img/noback.png" class="nicole-homeclass rounded-3">
              <img v-if="config && config.old_images && config.old_images=='Y' && 
                  (item.lesson.ltid == 'listening_test' || item.lesson.ltid == 'video')" v-real-img="'data:image/jpeg;base64,'+item.lesson.image_url" src="assets/img/img_offline_null.png" class="nicole-homeclass rounded-3">
              <img v-if="config && config.old_images && config.old_images=='N' && item.lesson.ltid == 'essay'" v-real-img="item.lesson.image" src="assets/img/noback.png" class="nicole-homeclass rounded-3">
              <img v-if="config && config.old_images && config.old_images=='N' && (item.lesson.ltid == 'listening_test' || item.lesson.ltid == 'video')" v-real-img="item.lesson.image" src="assets/img/img_offline_null.png" class="nicole-homeclass rounded-3">
            </div>
            <div class="w-70 pt-1 ms-2 ms-md-0">
              <div class="col-10"> 
                <img src="assets/img/extra-2.png" class="img-fluid  processimg mb-2" v-if="(item.lesson.ltid == 'video' || item.lesson.ltid == 'listening_test') && item.history_user_lesson_schedule != null && item.history_user_lesson_schedule.complete_5 == 'Y'">
                <img src="assets/img/extra-1.png" class="img-fluid  processimg mb-2" v-else-if="(item.lesson.ltid == 'video' || item.lesson.ltid == 'listening_test')">
                <img src="assets/img/img017.png" class="img-fluid  processimg mb-2" v-else-if="item.history_user_lesson_schedule != null && item.history_user_lesson_schedule.complete_3 == 'Y'">
                <img src="assets/img/img016.png" class="img-fluid  processimg mb-2" v-else-if="item.history_user_lesson_schedule != null && (item.history_user_lesson_schedule.essay == 'Y' || item.history_user_lesson_schedule.exam == 'Y' || item.history_user_lesson_schedule.explanation == 'Y' || item.history_user_lesson_schedule.word_preview == 'Y' || item.history_user_lesson_schedule.word_review == 'Y')">
                <img src="assets/img/img015.png" class="img-fluid  processimg mb-2" v-else>
              </div>
              <!-- eslint-disable vue/no-v-html -->
              <h6 class="text-g fw-boldest hide2 lh-sm text-card-title mt-1" v-html="item.lesson.name"></h6>
              <h5 class="text-card-small text-gray hide2" v-html="item.lesson.explanation"></h5>
              <!--eslint-enable-->
              
              <div class="d-flex"> 
                
                <div class="w-100 d-flex align-content-center justify-content-end">
                  <span 
                    class="text-card-small text-gray hide2" 
                    style="align-self: center; display:contents;"
                    v-if="item.switch == 'N'"
                  >
                    已下架
                  </span>
                  <div>
                    <span class="badge badge-secondary text-xs rounded-pill p-1 px-2 ms-1">{{item.english_level.name}}</span>
                    <span class="badge badge-secondary text-xs rounded-pill p-1 px-2 ms-1" v-for="(mode) in item.lesson_mode" :key='mode.lmid'>{{mode.name}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>    
        </div>  
        <!--一個組合 end-->
      </div>
    </div>
    
    <div class="text-center mt-5">
      <button type="button" class="btn btn-lg btn-outline-success rounded-pill w-90 text-s  nicole-noshadow" @click="openkCustomizeAlert">我要重新自訂等級/課程 </button>
    </div>
    
    <div class="nicolespace"></div>  
    <div class="nicolespace"></div>


    <!-- 每週任務說明 -->
    <div class="modal fade " id="notice7" tabindex="-1" role="dialog" aria-labelledby="notice7Label" aria-hidden="true">
      <div class="modal modal-dialog modal-dialog-centered">
        <div class="modal-content nicole-modal">
          <div class="modal-header">
            <h4 class="text-center text-lg fw-bold text-dark mb-0">每週任務說明</h4>
          </div>
          <div class="modal-body">
            <div class="d-flex mb-4">
              <img src="assets/img/img008.png" class="avatar-check rounded-0 me-2" style="height:24px !important;">       
              <h5 class="text-simbold text-s text-dark mb-0">依照你選擇的每週學習天數，提供文章學習Mission</h5>
            </div>    
            <div class="d-flex mb-4">
              <img src="assets/img/img008.png" class="avatar-check rounded-0 me-2" style="height:24px !important;">       
              <h5 class="text-simbold text-s text-dark mb-0">VIP 會員可額外獲得一個Extra 任務</h5>
            </div>    
            <div class="d-flex mb-4">
              <img src="assets/img/img008.png" class="avatar-check rounded-0 me-2" style="height:24px !important;">       
              <h5 class="text-simbold text-s text-dark mb-0">不限定學習順序</h5>
            </div>    
            <div class="d-flex mb-4">
              <img src="assets/img/img008.png" class="avatar-check rounded-0 me-2" style="height:24px !important;">       
              <h5 class="text-simbold text-s text-dark mb-0">完成全部Mission 與Extra 任務即可解鎖新課程</h5>
            </div>    
            <div class="d-flex">
              <img src="assets/img/img008.png" class="avatar-check rounded-0 me-2" style="height:24px !important;">       
              <h5 class="text-simbold text-s text-dark mb-0">已完成的學習任務在「我的課程」</h5>
            </div>
          </div>
          <div class="modal-footer ">
            <h5 class="text-success text-simbold mb-0 text-mm w-50 text-center py-1" data-bs-dismiss="modal">我知道了</h5>           
          </div>   
        </div>
      </div>
    </div>
    <!-- 學習日曆圖示說明 -->
    <div class="modal fade " id="notice9" tabindex="-1" role="dialog" aria-labelledby="notice9Label" aria-hidden="true">
      <div class="modal  modal-dialog modal-dialog-centered">
        <div class="modal-content nicole-modal">
          <div class="modal-header">
            <h4 class="text-center text-lg fw-bold text-dark mb-0">學習日曆圖示說明</h4>
          </div>
          <div class="modal-body">    
            <div class="d-flex align-content-center  mb-4">
              <div class="col-2">
                <div class="daygreen bg-white position-relative rounded-3">
                  <div class="nicole-member-center text-success text-simbold text-center text-s">8</div>
                  <div class="yellowline rounded-3 mt-1"></div>
                </div>
              </div>
              <div class="text-simbold text-s text-dark mb-0 col-10 pt-1">今天日期</div>
            </div>    
            <div class="d-flex align-content-center  mb-4">
              <div class="col-2">
                <div class="daygreen bg-success p-3 position-relative rounded-3">
                  <div class="nicole-member-center text-white text-bold text-center text-s">8</div>
                </div>
              </div>
              <div class="text-simbold text-s text-dark mb-0 col-10 pt-1">連續使用APP達10分鐘，即完成打卡</div>
            </div>     
            <div class="d-flex align-content-center  mb-3">
              <div class="col-2">
                <div class="daygreen bg-success p-3 position-relative rounded-3">
                  <i class="fas fa-star fa-2xs  daystar"></i>
                  <div class="nicole-member-center text-white text-bold text-center text-s">8</div>
                </div>
              </div>
              <div class="text-simbold text-s text-dark mb-0 col-10 pt-1">完成APP中任一學習，即獲得星星</div>
            </div>   
          </div>
          <div class="modal-footer ">
            <h5 class="text-success text-simbold mb-0 text-mm w-50 text-center py-1" data-bs-dismiss="modal">我知道了</h5>
          </div>   
        </div>
      </div>
    </div>
  </body>
  <Footer/>
</div>
</template>



<script>
import Header from '@/components/Header.vue'
import { oltConsole , oAC } from "@/lib/oltModule/initClient";
import CommonAlert1 from '@/components/CommonAlert1.vue'
import CommonAlert2Func from '@/components/CommonAlert2Func.vue'
import DailyLearnVAlert       from '@/components/DailyLearn/DailyLearnVAlert.vue'
import DailyLearnTrophyAlert  from '@/components/DailyLearn/DailyLearnTrophyAlert.vue'
import Footer from '@/components/Footer.vue'
import { config } from "@/include/config"; 


export default {
  name: 'DailyLearn',
  components: {
    Header,
    CommonAlert1,
    CommonAlert2Func,
    DailyLearnVAlert,
    DailyLearnTrophyAlert,
    Footer,
  },
  data() {
    return { 
      // api setting
      setDebugMode:false ,
      apiDebug: false,
      apiName: "",
      act: "",
      fun: "",
      form:{
        seq: 10,
        switch: true,
      },

      //alert
      title : "",
      content:"",
      btnNameUp:"",
      btnNameDown:"",
      onFuncUp: Function,
      onFuncDown: Function,

      // ui data
      name: "",
      englishName: "",
      tempIcon: "",
      icon: "",
      todayLearn: 0,
      countHour: "0",
      countMinutes: "0",
      currentLesson: "0",
      maxLesson: "50 課",
      englishText: "",
      tempLessonList: [], // 主要資料
      finalMissionCount: 0, // 最後的任務總數
      curMissionCount: 0, // 當前完成的任務數量
      ApiRes: {},

        config:"",

      // Status
      isNoData: false,
      showBlue: 1,
      showRed: 2,
      showYellow: 3,
      isShowEnglishColor: 0,
      showWeek: true,
      showMonth: false,

      isRepeat: "N", // 不要重複彈出視窗
      isAddedNew: false, // 是否已經新增

      dontShowDailyLearn: false,


      // Calendar
      // https://zhuanlan.zhihu.com/p/
      currYear: "", // 當前年份
      currMonth: "", // 當前月份

      month_olympi: [31,29,31,30,31,30,31,31,30,31,30,31],
      month_normal: [31,28,31,30,31,30,31,31,30,31,30,31],
    }
  },
  methods: {
    setOnBack(){
      this.$JsBridge.callHandler('setOnback', '', this.onBack);
    },
    onBack(){
      $("#notice7").modal("hide");
      $("#notice9").modal("hide");
    },
    getPageDailyLearn(){
      this.apiName = "page_daily_learn";
      oAC.getAllRecord(oAC.getApiParam(this), {uid: this.$GlobalData.uid}).then(res => {
        if (res != undefined) {
          this.ApiRes = res;

          // 使用者資料
          if (res.user != undefined && res.user.length > 0) {
            if (res.user[0].name != undefined && res.user[0].name.length > 0) {
              this.name = res.user[0].name;
            } else {
              this.name = "Student";
            }

            // 確認是否有英文等級
            if (res.user[0].english_level_name != undefined && res.user[0].english_level_name.length > 0) {
              this.englishName = res.user[0].english_level_name;
            } else {
              this.englishName = "";
              this.isAddedNew = false;
            }

            if(res.user[0].image_url != undefined && res.user[0].image_url.length > 0){
              
            if(config && config.old_images && config.old_images=="Y") 
            {             
              this.tempIcon = res.user[0].image_url;
              this.getUserIcon();
            } 
            else 
            {  
              this.tempIcon = res.user[0].image;
              this.icon = res.user[0].image;
            } 
            
            }
            if (res.english_lesson != undefined ){
              this.maxLesson = res.english_lesson+" 課";
            }
          }


          // 今日學習
          if (res.today_learn  != undefined && res.today_learn.length > 0){
            this.todayLearn = res.today_learn[0]['learn_time'];
          }

          // 累計學習
          if(res.total_minutes != undefined){
            var hours = res.total_minutes / 60;
            var minutes = res.total_minutes % 60;
            this.countHour = Math.floor(hours);
            this.countMinutes = Math.floor(minutes);
          }

          // 已學習課程數 / 已學習課程數上限
          if(res.already_learn  != undefined && res.already_learn.length > 0){
            this.currentLesson = res.already_learn[0].learn_lessons;
            
          }

          // 學習任務
          if(res.primary_item_list  != undefined && res.primary_item_list.length > 0){
            this.isNoData = false;
            this.finalMissionCount = res.primary_item_list[0].length;
            if(this.finalMissionCount > 0){
              this.tempLessonList = res.primary_item_list[0];
            }
            this.isAddedNew = true;

            for (var i = 0; i < res.primary_item_list[0].length; i++) {
              // 總計 完成任務總數(curMissionCount)
              if (res.primary_item_list[0][i].history_user_lesson_schedule != undefined && 
                  (res.primary_item_list[0][i].history_user_lesson_schedule.complete_5 == 'Y' || res.primary_item_list[0][i].history_user_lesson_schedule.complete_3 == 'Y')) {
                this.curMissionCount++;
              }

              // 假如有課程下架, 需求完成任務總數(finalMissionCount) 需減少
              if (res.primary_item_list[0][i].switch == 'N'){
                this.finalMissionCount--;
              }
            }

            // 確認 所有任務 是否等於 現在任務
            if (this.finalMissionCount == this.curMissionCount) {
              this.dailyLearnVAlertID = "alert1";
              this.onFuncUp = this.unlockNewLesson;
              this.$refs.dailyLearnVAlert.onShow();
              this.isAddedNew = false;
            }
            // 經過新增課程後, 全部都下架, 刪減至0, 即顯示解鎖新課程

          } else{
            // 沒有學習任務
            this.isNoData = true;
            this.isAddedNew = false;
          }

          // 是否有獎勵文案
          if(res.english_level_information  != undefined && res.english_level_information.length > 0){
            switch(res.english_level_information[0]["background_color"]){
              // 藍色
              case "c9dcff": this.isShowEnglishColor = this.showBlue; break;
              // 紅色
              case "ffeecc": this.isShowEnglishColor = this.showRed; break;
              // 黃色
              case "ffcfe0": this.isShowEnglishColor = this.showYellow; break;
            }

            this.englishText = res.english_level_information[0]["description"];
          }

          // 月曆任務
          if(res.history_user_daily_task != undefined && res.history_user_daily_task.length > 0){
            updateCalendarCallback(res.history_user_daily_task);
          }

          setVueUpdateCalendar(this.updateCalendar);

          showCurrentWeek();

          setUid(this.$GlobalData.uid);

          setMoveListener();
        }
      }).catch((err)=> {
        oltConsole.log(err);
      });
    },
    getUserIcon(){
      if(this.tempIcon != ""){
        this.apiName = "page_mine_icon";
        oAC.getAllRecord(oAC.getApiParam(this), {url:this.tempIcon}).then(res => {
            if(res && res.image && res.image != ""){                        
                this.icon = res.image;
            } else {
                this.icon = "";
            }
          }
        ).catch((err)=> {
            this.icon = "";
            oltConsole.log(err);
        });
      }
    },
    openCustomLevelSelect(){
      this.$router.push({
        name : 'CustomLevelSelect',
        params : {
          backUrl: this.$route.name,
        }
      });
    },
    openLevelExamIntro(){
      this.$router.push({
        name : 'LevelExamIntro',
        params : {
          back: this.$route.name,
        }
      });
    },
    openkCustomizeAlert(){
      this.title = '';
      this.content = '開始課程前，您可以先自行定義您想要學習的等級；或者先參加等級測試，來了解適合自己的等級課程喲！';
      this.btnNameUp = '自訂等級';
      this.btnNameDown = '參加等級測驗';
      this.onFuncUp = this.openCustomLevelSelect;         
      this.onFuncDown = this.openLevelExamIntro;           
      this.$refs.commonAlert2Func.onShow();
    },
    openCalendar(){
      if (this.showWeek) {
        this.showWeek = false;
        this.showMonth = true;
        refreshDate();
      } else if (this.showMonth) {
        this.showWeek = true;
        this.showMonth = false;
        showCurrentWeek();
      }
    },
    unlockNewLesson(){
      this.finalMissionCount = 0;
      this.curMissionCount = 0;
      this.apiName = "page_daily_learn_unlock_lesson?uid="+this.$GlobalData.uid;
      this.form["uid"] = this.$GlobalData.uid;
      this.fun = "DailyLearn";
      this.act = "put"; 

      oAC.putRecord(oAC.getApiParam(this), {}).then(res => {
        if (res != undefined && res.error != undefined) {
          this.title = "錯誤";
          this.content = "第一次使用需要先自訂等級和學習天數";
          this.btnNameUp = "確定";
          this.onFuncUp = this.openCustomLevelSelect;
          this.$refs.commonAlert1.onShow();
        }

        // 重複提醒
        if (res.check_is_repeat != undefined) {
          this.isRepeat = res.check_is_repeat;
          this.learningSoStrong();
        }

        this.isRepeat = (res.check_is_repeat != undefined) ? res.check_is_repeat : "N";
        if (res.isAddedNew != undefined && res.isAddedNew == "Y") {
          this.isAddedNew = true;
        } else {
          this.isAddedNew = false;
        }
        (this.isAddedNew) ? this.updateMission() : [];

        // 等待 commonAlert2Func 關閉, 再開啟另一個
        setTimeout(this.learningSoStrong, 500);
      }).catch((err)=> {
        oltConsole.log(err);
        if (err != undefined && 
            err.response != undefined && 
            err.response.data != undefined && 
            err.response.data.error != undefined) {
          this.title = "";
          this.content = "第一次使用需要先自訂等級和學習天數";
          this.btnNameUp = "確定";
          this.onFuncUp = this.openCustomLevelSelect;
          this.$refs.commonAlert1.onShow();
        }
      });
    },
    learningSoStrong(){
      if(this.isRepeat == "Y"){
        this.onFuncDown = null;
        this.$refs.dailyLearnTyophyAlert.onShow();
        this.isAddedNew = false;
        this.$GlobalData.isRepeat = false;
      }
    },
    openVideoLessonCallback(data){},
    openLesson(item){
      if (item.switch == 'Y'){
        switch(item.ltid){
          case "essay":
            this.$router.push({
              name : 'TaskList',
              params : {
                backUrl: this.$route.name,
                taskBack: this.$route.name,
                lsid: item.lsid
              }
            });
          break;
          case "video":
            this.$JsBridge.callHandler('openVideoLesson',JSON.stringify({
                lsid: item.lsid,
                backUrl: `DailyLearn`,
                mode: "vue",
            }), this.openVideoLessonCallback);
          break;
          case "listening_test":
            if(config && config.old_exam && config.old_exam=="Y") {
                this.$JsBridge.callHandler('openWebView', '/listening_exam_start?lsid='+item.lsid +'&vue=DailyLearn', this.changeToWebCallback);
            }else{
              this.$router.push({
                name : 'ListeningExamStart',
                query : {
                  back: this.$route.name,
                  lsid: item.lsid
                }
              });
            }
          break;
        }
      }
    },
    changeToWebCallback(){
    },
    updateCalendar(json){
      if (json == undefined) { return false;}
      this.apiName = "page_daily_learn_update_calednar";
      this.currYear = json.year;
      this.currMonth = json.month;
      oAC.getAllRecord(oAC.getApiParam(this), {uid: json.uid, year: json.year, month: json.month}).then(res => {
        if(res.history_user_daily_task != undefined && res.history_user_daily_task.length > 0){
          updateCalendarCallback(res.history_user_daily_task);
        } else {
          updateCalendarCallback();
        }
        this.$forceUpdate();
      }).catch((err)=> {
        oltConsole.log(err);
      });
    },
    updateMission(data){
      this.getPageDailyLearn();
      this.isNoData = false;
    },
    showModal(target){
      $(target).modal("show");
    },
    JumpToTop(){
      // 自動將畫面移動到最上方
      $("html, body").animate({ scrollTop: $("html").offset().top }, {duration: 500,easing: "swing"});
    }
  },
  created(){
    if (this.$JsBridge.isAndroid) {
      this.setOnBack();
    }
    this.JumpToTop();
    if (this.$route.query.uid != undefined) {
      this.$GlobalData.uid = this.$route.query.uid;
    }
    this.isRepeat = this.$GlobalData.isRepeat;
    var Today = new Date();
    this.currYear = Today.getFullYear();
    this.currMonth = Today.getMonth()+1;
    this.getPageDailyLearn();

    Today = new Date();
    my_year = Today.getFullYear();
    my_month = Today.getMonth();
    my_day = Today.getDate();
    my_week = Today.getDay();

  },
  mounted(){
    this.config = config;
    if (this.$route.query.uid != undefined){ 
      this.$GlobalData.uid = this.$route.query.uid;
    }
  }
};
</script>
<style>
  .user_icon{
    margin-left: 20px;
    position: absolute;
    width: 5vw;
    object-fit: cover !important;
    height: 5vw;
    margin-top: 4px;
  }
</style>