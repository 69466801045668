<template>
    <div id="DailyLearnVAlert">
        <div class="modal fade " :id="modalId" tabindex="-1" role="dialog" aria-labelledby="notice5Label" aria-hidden="true">
            <div class="  modal-dialog modal-dialog-centered">
                <div class="modal-ssm modal-content nicole-modal">
                <div class="modal-body">    
                    <div class=" mt-n5  text-center"> 
                    <img class="avatar avatar-modal" src="assets/img/img026.png">   
                    <h4 class="text-center text-mm text-simbold text-dark mt-2 mb-3">恭喜你成功刷新個人學習記錄囉！</h4>
                    <h4 class="text-center text-mm  text-dark">繼續挑戰新的課程任務吧！</h4>
            </div> 
                </div>
                <div class="modal-footer border-white pt-1">
                    <button type="button" class="btn btn-lg btn-success rounded-pill w-60 px-1" @click="onFuncUp">馬上解鎖新課程</button>
                    <button type="button" class="btn btn-outline-success rounded-pill w-60 nicole-noshadow py-2 px-1" @click="onFuncDown" >下次解鎖</button>
                </div> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'DailyLearnVAlert',    
    props: {
        onfuncup: Function,
        onfuncdown: Function,
        id: String,
    }, 
    data () {
        return { 
        modalId: "",
        }
    },
    methods: {
        onShow(){
            $('#'+this.modalId).modal("show");
        },
        onHide(){
            if ($("body").hasClass("modal-open")) $("body").removeClass("modal-open");
            $('#'+this.modalId).modal("hide");
        },
        onFuncUp(){
            if(this.onfuncup != null && this.onfuncup != undefined){
                $('#'+this.modalId).on('hidden.bs.modal', () => {
                    $('#'+this.modalId).off('hidden.bs.modal');
                    this.onfuncup();
                });
            }
            if ($("body").hasClass("modal-open")) $("body").removeClass("modal-open");
            $('#'+this.modalId).modal("hide");
        },
        onFuncDown(){
            if(this.onfuncdown != null && this.onfuncdown != undefined){
                $('#'+this.modalId).on('hidden.bs.modal', () => {
                    $('#'+this.modalId).off('hidden.bs.modal');
                    this.onfuncdown();
                });
            }
            if ($("body").hasClass("modal-open")) $("body").removeClass("modal-open");
            $('#'+this.modalId).modal("hide");
        },
    },
    created() {  
        if(this.id && this.id != "" && this.id != undefined){
            this.modalId = this.id;
        }else{
            this.modalId = "dailyLearnVAlert";
        }
    },
    mounted(){  
    }
};

</script>