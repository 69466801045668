<template>
    <div id="DailyLearnTrophyAlert">
        <div class="modal fade " :id="modalId" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-ssm  modal-dialog modal-dialog-centered">
                <div class="modal-content nicole-modal">
                <div class="modal-body">    
                    <div class=" mt-n5  text-center"> 
                    <img class="avatar avatar-modal" src="assets/img/img027.png">   
                    <h4 class="text-center text-s text-simbold text-dark lh-lg  mb-0 ">你的學習力太強大了！</h4>
                    <h4 class="text-center text-s  text-dark lh-lg  mb-0">稍微休息一下下~<br>期待我們提供的新課程唷！</h4>
            </div> 
                </div>
                <div class="modal-footer border-white pt-1">
                    <!-- <button type="button" class="btn btn-lg btn-success rounded-pill w-75  text-simbold" @click="onFuncUp">去看看深度學習</button> -->
                    <button type="button" class="btn btn-outline-success rounded-pill w-50   nicole-noshadow py-2" @click="onFuncDown">我先複習</button>
                </div> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'DailyLearnTrophyAlert',    
    props: {
        onfuncup: Function,
        onfuncdown: Function,
        id: String,
    }, 
    data () {
        return { 
        modalId: "",
        }
    },
    methods: {
        onShow(){
            $('#'+this.modalId).modal("show");
        },
        onHide(){
            if ($("body").hasClass("modal-open")) $("body").removeClass("modal-open");
            $('#'+this.modalId).modal("hide");
        },
        onFuncUp(){
            if(this.onfuncup != null && this.onfuncup != undefined){
                $('#'+this.modalId).on('hidden.bs.modal', () => {
                    $('#'+this.modalId).off('hidden.bs.modal');
                    this.onfuncup();
                });
            }
            if ($("body").hasClass("modal-open")) $("body").removeClass("modal-open");
            $('#'+this.modalId).modal("hide");
        },
        onFuncDown(){
            if(this.onfuncdown != null && this.onfuncdown != undefined){
                $('#'+this.modalId).on('hidden.bs.modal', () => {
                    $('#'+this.modalId).off('hidden.bs.modal');
                    this.onfuncdown();
                });
            }
            if ($("body").hasClass("modal-open")) $("body").removeClass("modal-open");
            $('#'+this.modalId).modal("hide");
        },
    },
    created() {  
        if(this.id && this.id != "" && this.id != undefined){
            this.modalId = this.id;
        }else{
            this.modalId = "dailyLearnTrophyAlert";
        }
    },
    mounted(){  
    }
};

</script>